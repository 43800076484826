import { default as about_45passnJeKfxemqxMeta } from "/codebuild/output/src2313473482/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenavia7KnAOGAyYMeta } from "/codebuild/output/src2313473482/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementmzOgM7HP5mMeta } from "/codebuild/output/src2313473482/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliaspsSFVMXbPMMeta } from "/codebuild/output/src2313473482/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmYZZiaOOz75Meta } from "/codebuild/output/src2313473482/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliaskDRWUZ7vnEMeta } from "/codebuild/output/src2313473482/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editXnghKDyjMGMeta } from "/codebuild/output/src2313473482/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as index36sEcMgsjrMeta } from "/codebuild/output/src2313473482/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmcEYV5DwJT2Meta } from "/codebuild/output/src2313473482/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexVbSJ2wF4dfMeta } from "/codebuild/output/src2313473482/src/src/pages/card/create/index.vue?macro=true";
import { default as indexaBw2djkXAdMeta } from "/codebuild/output/src2313473482/src/src/pages/card/index.vue?macro=true";
import { default as completeSzX10NbVYGMeta } from "/codebuild/output/src2313473482/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmt37NJhtNKGMeta } from "/codebuild/output/src2313473482/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerOsPRMUbW26Meta } from "/codebuild/output/src2313473482/src/src/pages/contact-form/register.vue?macro=true";
import { default as contact5WnwUW2m1IMeta } from "/codebuild/output/src2313473482/src/src/pages/contact.vue?macro=true";
import { default as _91id_931LomSMS95XMeta } from "/codebuild/output/src2313473482/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexuA0g4ncDomMeta } from "/codebuild/output/src2313473482/src/src/pages/coupon/index.vue?macro=true";
import { default as faq1BHcmvQrQBMeta } from "/codebuild/output/src2313473482/src/src/pages/faq.vue?macro=true";
import { default as indexTwIVT2i6qPMeta } from "/codebuild/output/src2313473482/src/src/pages/history/index.vue?macro=true";
import { default as _91id_9334PSckxt9oMeta } from "/codebuild/output/src2313473482/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexiETfOmGF54Meta } from "/codebuild/output/src2313473482/src/src/pages/index.vue?macro=true";
import { default as maintenancelIOUd7dbF4Meta } from "/codebuild/output/src2313473482/src/src/pages/maintenance.vue?macro=true";
import { default as completemEK4CSt62rMeta } from "/codebuild/output/src2313473482/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmBrypx9MAq3Meta } from "/codebuild/output/src2313473482/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerMAF99R7G0aMeta } from "/codebuild/output/src2313473482/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codexQ0bKS3cwJMeta } from "/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmXgFC5C9VzGMeta } from "/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as register7b7hIeAFPVMeta } from "/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuuwZeSVhhTbMeta } from "/codebuild/output/src2313473482/src/src/pages/menu.vue?macro=true";
import { default as indexm6TiwcIbZYMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/index.vue?macro=true";
import { default as indexXDdI8fWpmwMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sample7vaZZ76e01Meta } from "/codebuild/output/src2313473482/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completeH9BJAvglO1Meta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45code28SAwPgGayMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexxfUO1wKBOBMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexIfG36PsWbPMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeuEg7DNPMfyMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmrudrqjTIEHMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexl4eXHTzGNjMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updateaomDiFOC9tMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmNdx2xaZq0vMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexlDpDFq4fBxMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updatedLbarGSJ4QMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completexTmJmHdrzhMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatesW8wwF2ssRMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexFMAA9AnWKLMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93H1UUpNzkKWMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completebR4wsf2sdbMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmsoBQwEioojMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completezYJvmWZfPGMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmrqrU49xZ9QMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentvjdG7cfc4tMeta } from "/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_931FbjFijHKuMeta } from "/codebuild/output/src2313473482/src/src/pages/notice/[id].vue?macro=true";
import { default as indexpu99FwAqmcMeta } from "/codebuild/output/src2313473482/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93wG0ZacwLFdMeta } from "/codebuild/output/src2313473482/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementDGeIzAKBeCMeta } from "/codebuild/output/src2313473482/src/src/pages/pass-agreement.vue?macro=true";
import { default as complete0AHu3bdwPnMeta } from "/codebuild/output/src2313473482/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeMr5zQRSQnRMeta } from "/codebuild/output/src2313473482/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordZQZgleIc2sMeta } from "/codebuild/output/src2313473482/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailvhnvjElsk5Meta } from "/codebuild/output/src2313473482/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codegBbxJIgMW7Meta } from "/codebuild/output/src2313473482/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexP1XgYq1y1UMeta } from "/codebuild/output/src2313473482/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45code6PgHAs9fAQMeta } from "/codebuild/output/src2313473482/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexJiyrNv1LhMMeta } from "/codebuild/output/src2313473482/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialIkJmAj9kUOMeta } from "/codebuild/output/src2313473482/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliaspsSFVMXbPMMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmYZZiaOOz75Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliaskDRWUZ7vnEMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editXnghKDyjMGMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmcEYV5DwJT2Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexVbSJ2wF4dfMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeSzX10NbVYGMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmt37NJhtNKGMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerOsPRMUbW26Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexuA0g4ncDomMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexTwIVT2i6qPMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiETfOmGF54Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completemEK4CSt62rMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmBrypx9MAq3Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerMAF99R7G0aMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codexQ0bKS3cwJMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmXgFC5C9VzGMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: register7b7hIeAFPVMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuuwZeSVhhTbMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexm6TiwcIbZYMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexXDdI8fWpmwMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sample7vaZZ76e01Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexFMAA9AnWKLMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completebR4wsf2sdbMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmsoBQwEioojMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completezYJvmWZfPGMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmrqrU49xZ9QMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentvjdG7cfc4tMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexpu99FwAqmcMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93wG0ZacwLFdMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: complete0AHu3bdwPnMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeMr5zQRSQnRMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordZQZgleIc2sMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailvhnvjElsk5Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codegBbxJIgMW7Meta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexP1XgYq1y1UMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45code6PgHAs9fAQMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexJiyrNv1LhMMeta || {},
    component: () => import("/codebuild/output/src2313473482/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2313473482/src/src/pages/tutorial.vue")
  }
]