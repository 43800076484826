import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp;
  const config = useRuntimeConfig();
  const router = useRouter();
  const appEnv = config.public.env;

  Sentry.init({
    app: [vueApp],
    dsn: config.public.sentryDsn,
    enabled: appEnv !== 'local',
    environment: appEnv,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
        interactionsSampleRate: 0.5,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // browserTracingの有効なドメイン
    tracePropagationTargets: ['localhost', /^\//],

    // サンプリング率
    tracesSampleRate: appEnv === 'prod' ? 0.06 : 1.0,
    // エラーの有無に関わらずユーザーのセッション全体に渡って計測する比率（Session Replay）
    replaysSessionSampleRate: appEnv === 'prod' ? 0.02 : 0,
    // エラーが発生する1分前からセッションの終了までを計測する比率（Session Replay）
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      "channel named 'default' not found in flutter", // flutterを介さずにブラウザでアプリを見たときのエラー
      'null is not an object', // iOSでGoogle検索アプリを入れると発生するエラー https://github.com/getsentry/sentry-javascript/issues/756
      // 仕様上問題ないエラー
      '位置情報が取得できませんでした',
      'トークンからカード情報を取得できませんでした。', // ユーザーがカード情報を誤って入力したときのエラー
      // 決済エラー
      '決済処理をキャンセルしました',
      '精算時にエラーが発生しました。もう一度やり直してください。',
      '決済時にエラーが発生しました。お手数ですが、始めからやり直してください。',
      // GoogleMap関連エラー
      'Failed to parse style table.', // 特に影響はなさそうなので無視
      'Could not find a 3d context', // 特に影響はなさそうなので無視
      "Could not load 'webgl'", // 特に影響はなさそうなので無視
      "Could not load 'marker'", // 特に影響はなさそうなので無視
      "Could not load 'util'", // 特に影響はなさそうなので無視
      "Could not load 'map'", // 特に影響はなさそうなので無視
      "Could not load 'overlay'", // 特に影響はなさそうなので無視
      'Images took longer than 15s to load', // 特に影響はなさそうなので無視
      'Reflect.get requires the first argument be an object', // GoogleMap内部エラー
      'Status must be between 200 and 599', // iOS Safari限定のエラー（GoogleAnalyticsへのログ送信がstatus=0を返すことに由来）
      'access_denied: The resource owner denied the request.; error=access_denied', // LINE側から返されるエラーなので無視
      '郵便番号の形式が正しくありません。', // バリデーションエラー
      '郵便番号が存在しません。', // バリデーションエラー
      '車庫番号が指定されていません', // バリデーションエラー
    ],

    beforeSend(event, hint) {
      // iOSでmap_styleの解釈に失敗するエラーをグルーピング
      if (event.message === 'Failed to parse style table.') {
        event.fingerprint = ['failed-to-parse-style-table'];
      }
      if (!event.exception) return event;
      // ログに出力
      console.error(
        `[Exeption handled by Sentry]: (${hint.originalException}, , { event, hint })`,
        { event, hint },
      );

      // GoogleMap関連のエラーは無視
      const filename =
        event.exception.values?.[0].stacktrace?.frames?.slice(-1)[0].filename;
      if (filename?.includes('maps-api-v3')) return null;

      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });
});
