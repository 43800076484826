import validate from "/codebuild/output/src2313473482/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45first_45login_45global from "/codebuild/output/src2313473482/src/src/middleware/01.firstLogin.global.ts";
import _02_45auth_45global from "/codebuild/output/src2313473482/src/src/middleware/02.auth.global.ts";
import to_45error_45global from "/codebuild/output/src2313473482/src/src/middleware/toError.global.ts";
import manifest_45route_45rule from "/codebuild/output/src2313473482/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45first_45login_45global,
  _02_45auth_45global,
  to_45error_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "coupon-params": () => import("/codebuild/output/src2313473482/src/src/middleware/couponParams.ts"),
  "editing-card": () => import("/codebuild/output/src2313473482/src/src/middleware/editingCard.ts"),
  "logout-control": () => import("/codebuild/output/src2313473482/src/src/middleware/logoutControl.ts"),
  "mypage-pass-params": () => import("/codebuild/output/src2313473482/src/src/middleware/mypagePassParams.ts"),
  "notice-params": () => import("/codebuild/output/src2313473482/src/src/middleware/noticeParams.ts"),
  "pass-payment-params": () => import("/codebuild/output/src2313473482/src/src/middleware/passPaymentParams.ts"),
  "payment-params": () => import("/codebuild/output/src2313473482/src/src/middleware/paymentParams.ts"),
  "payment-step": () => import("/codebuild/output/src2313473482/src/src/middleware/paymentStep.ts"),
  "search-params": () => import("/codebuild/output/src2313473482/src/src/middleware/searchParams.ts"),
  "set-redirect-url": () => import("/codebuild/output/src2313473482/src/src/middleware/setRedirectUrl.ts"),
  "sns-integration": () => import("/codebuild/output/src2313473482/src/src/middleware/snsIntegration.ts"),
  "unsubscribe-store-control": () => import("/codebuild/output/src2313473482/src/src/middleware/unsubscribeStoreControl.ts")
}